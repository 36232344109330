.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex: 1;
    background-image:url('/public/DALLE BG.png');
  }
  
  
  .login{
    background-color: #000; /* White background */
    color: #fff; /* Black text */
    border: 1px solid #FFF;
    border-radius: 5px;
    width: 308px;
    height: 308px;
    position: relative;
    align-content:center;
    text-align: center;
  }
  
  .rectangle {
    width: 215px;
    height: 86px;
    background-color: #fff; /* White background */
    color: #000; /* Black text */
    border: 1px solid #FFF;
    border-radius: 20px;
    position: relative;
    text-align: center;
    margin: auto;
    margin-top: auto;
  }
  
  .login-google-btn {
    margin: auto;
    margin-top: 20%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-color: black;
    cursor: pointer;
    padding: 20px 30px;
    border-radius: 20px; /* Slightly rounded corners for the button */
    font-size: 16px; /* Adjust as needed */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .cancel{
    margin:auto;
    margin-top: 15%;
    vertical-align: bottom;
    background-color: black;
    display: flex;
    color: white;
    border-color: white;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 10px; /* Slightly rounded corners for the button */
    font-size: 16px; /* Adjust as needed */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .google-icon {
    margin-left: 20px;
    font-size: 30px;
    
    /* Adjust size as needed, Font Awesome icons scale with font-size */
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .login h1{
    font-weight: 1;
    margin-top: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }